import './App.css';

import { BrowserRouter as Router,
  Routes,
  Route } from "react-router-dom"
import { Home } from './components/Home';
import { Footer } from './components/Footer';
import ResponsiveAppBar from './components/NavBar';
import React from 'react';
//import { Banned } from './components/Banned';

function App() {
  React.useEffect(() => {
  }, [])

  return (
    <div>
      <ResponsiveAppBar />
      <Router>
        <Routes>
          { /* <Route path="/banned" element={<Banned />} /> */}
          <Route path="/" element={<Home />} />
        </Routes>
      </Router>
      <Footer />
    </div>
  );
}

export default App;
