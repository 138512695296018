import { Container, Grid } from '@mui/material'
import * as React from 'react'
import { Fade, Slide } from 'react-reveal'
import EmailIcon from '@mui/icons-material/Email';

export const Home = (props) => {

    React.useEffect(() => {
        window.scrollTo()
    }, [])

    return (
        <div style={{padding: 0, margin: 0, overflow: 'hidden'}}>
            <div style={{display: 'flex',  paddingTop: '-60px', flexDirection: 'column', minHeight: '100vh'}} id="top">
                <Fade>
                    <div style={{display: 'flex', width: '100%', paddingBottom: 0, marginBottom: 0}}>
                        <img src={process.env.PUBLIC_URL + "images/front.webp"} style={{width: '100%'}} alt="The Rusty Nail" />
                    </div>
                    <div style={{textAlign: 'center', marginBottom: 0}} className="sanantonio" >
                        <h1 style={{textTransform: 'uppercase', paddingTop: '60px', paddingBottom: '60px', margin: 2}} className="sanantoniotext">San Antonio, Texas</h1>
                    </div>
                    <div style={{display: 'flex', width: '100%'}}>
                        <img src={process.env.PUBLIC_URL + "images/0526.webp"} style={{width: '100%'}} alt="The Rusty Nail" />
                    </div>
                </Fade>
            </div>
                

            <Container>
                <div style={{display: 'flex', paddingTop: '75px', flexDirection: 'column'}} id="food">
                    <Fade up>
                    <div className={'headerBanner bigText'}>
                        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%'}}>
                            <div style={{padding: '20px'}}>Appetizers</div>
                            <img src={process.env.PUBLIC_URL + "images/2252.webp"} style={{width: '100%', padding: '20px'}} alt="appetizers" />
                        </div>
                    </div>
                    </Fade>

                    <Grid container style={{ justifyContent: 'center', paddingBottom: '200px'}} spacing={3}>
                        <Grid item xs={12} md={4} className={'gridBottom'}>
                        <Slide bottom>
                            <div className={'foodName'}>Smoked Brisket Queso Skillet</div>
                            <Fade>
                                <div style={{textAlign: 'center'}}>
                                    <img src={process.env.PUBLIC_URL + 'images/5642.webp'} alt="Smoked Brisket Queso Skillet" className="gridPicture" />
                                </div>
                            </Fade>
                            <div className={'foodDesc'}>
                            Queso Blanco topped with smoked brisket & fresh pico and made to order chips
                            </div>
                        </Slide>
                        </Grid>


                        <Grid item xs={12} md={4} className={'gridBottom'}>
                        <Slide bottom>
                        <div className={'foodName'}>Chips and Queso</div>
                        <div className={'foodDesc'}>
                        Hand cut and made to order side of queso blanco
                        </div>
                        </Slide>
                        </Grid>

                        
                        <Grid item xs={12} md={4} className={'gridBottom'}>
                        <Slide bottom>
                        <div className={'foodName'}>Smoked Brisket Sliders (3)</div>
                        <Fade>
                            <div style={{textAlign: 'center'}}>
                                <img src={process.env.PUBLIC_URL + 'images/5254.webp'} alt="smoked brisket sliders" className="gridPicture" />
                            </div>
                        </Fade>
                        <div className={'foodDesc'}>
                        House smoked brisket served on toasted Hawaiian roll with house “Rusty” BBQ sauce,
                        pickle, and fried onion strings (side jalapeño)
                        </div>
                        </Slide>
                        </Grid>

                        <Grid item xs={12} md={4} className={'gridBottom'}>
                        <Slide bottom>
                        <div className={'foodName'}>Fried Pickles</div>
                        <div className={'foodDesc'}>
                        Served with a side of house smoked gouda ranch
                        </div>
                        </Slide>
                        </Grid>
                        
                        <Grid item xs={12} md={4} className={'gridBottom '}>
                        <Slide bottom>
                        <div className={'foodName '}>Smoked Mozzarella Cheese Bites</div>
                        <Fade>
                            <div style={{textAlign: 'center'}}>
                                <img src={process.env.PUBLIC_URL + 'images/5005.webp'} alt="Smoked Mozzarella Cheese Bites" className="gridPicture" />
                            </div>
                        </Fade>
                        <div className={'foodDesc'}>
                        Made fresh to order in our special blend of seasonings, served with a side of marinara
                        sauce.
                        </div>
                        </Slide>
                        </Grid>
                        
                        <Grid item xs={12} md={4} className={'gridBottom'}>
                        <Slide bottom>
                        <div className={'foodName'}>Cauliflower Florets</div>
                        <div className={'foodDesc'}>
                        Lightly breaded florets with house smoked gouda ranch side of buffalo sauce.
                        </div>
                        </Slide>
                        </Grid>
                        

                        <Grid item xs={12} md={4} className={'gridBottom '}>
                        <Slide bottom>
                        <div className={'foodName '}>Brussel Sprouts</div>
                        <Fade>
                            <div style={{textAlign: 'center'}}>
                                <img src={process.env.PUBLIC_URL + 'images/2252.webp'} alt="Brussel Sprouts" className="gridPicture" />
                            </div>
                        </Fade>
                        <div className={'foodDesc'} >
                        Crispy Sprouts dressed with a balsamic drizzle, parmesan cheese, topped with
                        fresh bacon, sea salt, cracked pepper and a side of house jalapeno tomato jam
                        </div>
                        </Slide>
                        </Grid>
                        
                        <Grid item xs={12} md={4} className={'gridBottom'}>
                        <Slide bottom>
                        <div className={'foodName'}>Chicken Tenders</div>
                        <div className={'foodDesc'}>
                        House battered hand cut with fries and a side of house made honey mustard
                        </div>
                        </Slide>
                        </Grid>
                        
                        <Grid item xs={12} md={4} className={'gridBottom '}>
                        <Slide bottom>
                        <div className={'foodName '}>Fish Tacos (3)</div>
                        <Fade>
                            <div style={{textAlign: 'center'}}>
                                <img src={process.env.PUBLIC_URL + 'images/2829.webp'} alt="Fish Tacos" className="gridPicture" />
                            </div>
                        </Fade>
                        <div className={'foodDesc'}>
                        Seared white fish topped with pineapple pico, drizzle of house spicy mayo and Cotija
                        cheese crumbles
                        </div>
                        </Slide>
                        </Grid>

                        <Grid item xs={12} md={4} className={'gridBottom'}>
                        <Slide bottom>
                        <div className={'foodName'}>White Truffle Fries</div>
                        <div className={'foodDesc'}>
                        White truffle oil, cracked pepper, sea salt, and topped with parmesan cheese
                        </div>
                        </Slide>
                        </Grid>
                        
                        <Grid item xs={12} md={4} className={'gridBottom '}>
                        <Slide bottom>
                        <div className={'foodName '}>Sweet Taters</div>
                        <div className={'foodDesc'}>
                        Served with a side of local Texas honey
                        </div>
                        </Slide>
                        </Grid>

                        <Grid item xs={12} md={4} className={'gridBottom'}>
                        <Slide bottom>
                        <div className={'foodName'}>Fried Okra</div>
                        <div className={'foodDesc'}>
                        Served with house smoked Gouda ranch
                        </div>
                        </Slide>
                        </Grid>

                        <Grid item xs={12} md={4} className={'gridBottom'} style={{paddingBottom: '0px !important'}}>
                        <Slide bottom>
                        <div className={'foodName'}>Fried Portabella Mushrooms</div>
                        <div className={'foodDesc'}>
                        Made fresh to order with our house smoked gouda ranch served on the side
                        </div>
                        </Slide>
                        </Grid>
                    </Grid>
                </div>
            </Container>

            <Container>
                <div style={{display: 'flex', flexDirection: 'column'}} >
                    <Fade up>
                    <div className={'headerBanner'} style={{marginTop: '0px', flexDirection: 'column', alignItems: 'center'}}>
                        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%'}}>
                            <div className='bigText' style={{paddingBottom: '15px'}}>Burgers</div>
                                <div className={'smallText'}>
                                *8 oz. angus patty cooked medium, both American and cheddar, brioche bun. Veggies
                                on side. Side of house habanero mango ketchup
                            </div>
                            <img src={process.env.PUBLIC_URL + "images/5154.webp"} style={{width: '100%', padding: '20px'}} alt="The Rusty Nail" />
                        </div>
                    </div>
                    </Fade>

                    <Grid container style={{ justifyContent: 'center', paddingBottom: '200px'}}  spacing={3}>
                        <Grid item xs={12} md={4} className={'gridBottom'}>
                            <Fade>
                            <div className={'foodName'}>Classic Cheeseburger</div>
                            <div style={{textAlign: 'center'}}>
                                <img src={process.env.PUBLIC_URL + 'images/4921.webp'} alt="Classic Cheeseburger" className="gridPicture" />
                            </div>
                            <div className={'foodDesc'}>Served with Fries</div>
                            </Fade>
                        </Grid>

                        <Grid item xs={12} md={4} className={'gridBottom'}>
                        <Fade>
                        <div className={'foodName'}>Portabella Mushroom Swiss Burger</div>
                        <div className={'foodDesc'}>Swiss cheese and sautéed mushrooms served with fries</div>
                        </Fade>
                        </Grid>

                        <Grid item xs={12} md={4} className={'gridBottom'}>
                        <Fade>
                            <div className={'foodName'}>BBQ Bacon Burger</div>
                        
                            <div style={{textAlign: 'center'}}>
                                <img src={process.env.PUBLIC_URL + 'images/0018.webp'} alt="BBQ Bacon Burger" className="gridPicture" />
                            </div>
                        </Fade>
                        <div className={'foodDesc'}>
                        Stacked with onion rings, Brisket, bacon, drizzled with Rusty BBQ sauce and served
                        with fries
                        </div>
                        </Grid>

                        <Grid item xs={12} md={4} className={'gridBottom'}>
                        <Fade>
                        <div className={'foodName'}>Chicken on a Stick</div>
                        <div className={'foodDesc'}>
                        Over sized, pickle brined marinated in smoked buttermilk hand battered chicken
                        breast served with a sweet Hawaiian roll, pickled jalapeno, and sweet Thai chili
                        </div>
                        </Fade>
                        </Grid>

                        <Grid item xs={12} md={4} className={'gridBottom'} style={{paddingBottom: '0px !important'}}>
                        <Fade>
                            <div className={'foodName'}>Grilled Chicken Sandwich</div>
                            <div className={'foodDesc'}>8 oz. chicken breast topped with Swiss cheese served with boom boom sauce and fries</div>
                        </Fade>
                        </Grid>
                    </Grid>
                </div>
            </Container>

            <div className="invertColors" style={{paddingBottom: '125px'}}>
            <Container  style={{display: 'flex', flexDirection: 'column'}} id="spirits">

                <h2 style={{textAlign: 'center', paddingTop: '75px'}}>Spirits</h2>
                <div style={{padding: '50px', textAlign: 'center'}}>We offer a wide variety of all different types of spirits.  </div>
                
                <Grid container>
                    <Grid item xs={12} md={4}>
                        <Fade>
                        <img src={process.env.PUBLIC_URL + "images/1225.webp"} style={{width: '100%'}} alt="The Rusty Nail" />
                        </Fade>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Fade>
                        <img src={process.env.PUBLIC_URL + "images/1552.webp"} style={{width: '100%'}} alt="The Rusty Nail" />
                        </Fade>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Fade>
                        <img src={process.env.PUBLIC_URL + "images/1051.webp"} style={{width: '100%'}} alt="The Rusty Nail" />
                        </Fade>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Fade>
                        <img src={process.env.PUBLIC_URL + "images/13.webp"} style={{width: '100%'}} alt="The Rusty Nail" />
                        </Fade>
                    </Grid>
                </Grid>
            </Container>
            </div>
                
            <Container>
                <div style={{display: 'flex',  paddingTop: '75px', flexDirection: 'column'}} id="location">
                    <h2 style={{textAlign: 'center'}}>Location</h2>
                    <iframe title={'TheRustyNail'} 
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3475.0729822449894!2d-98.79894238334165!3d29.426663282111292!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x865c414435467dc5%3A0x8f3d7d2901e64802!2sThe%20Rusty%20Nail!5e0!3m2!1sen!2sus!4v1650120798883!5m2!1sen!2sus"
                        width="100%" height="300" style={{border: '0', padding: 0, margin: 0}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>    
                </div>


                <div style={{display: 'flex',  paddingTop: '150px', paddingBottom: '150px', flexDirection: 'column'}} id="contact">
                    <h2 style={{textAlign: 'center'}}>Contact</h2>
                    <div style={{textAlign: 'center'}}><EmailIcon style={{fontSize: '50px', paddingBottom: '20px'}} /></div>
                    <div style={{textAlign: 'center', lineHeight: '1.5em'}}>For all inquries please email us at 
                        <a href="mailto:contact@therustynailsa.com" className='linkDestyle copperColor' style={{}}> contact@therustynailsa.com</a>
                    </div>
                </div>
            </Container>
            <div>
                <img src={process.env.PUBLIC_URL + "images/0314.webp"} style={{width: '100%'}} alt="The Rusty Nail" />
            </div>
        </div>
    )
}

