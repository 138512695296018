
import * as React from 'react'
import FacebookIcon from '@mui/icons-material/Facebook';
import { Grid } from '@mui/material';

export const Footer = (props) => {

    return (
        <div>
        <Grid container className={'footerClass'} >
            <Grid item xs={12} md={3} className={'gridFlex'}>
                <div className='copperColor p10'>Address</div>
                <div>
                    <a href="https://goo.gl/maps/NBEF1seiiqLiz9Pu7" target="_blank" rel={'noreferrer'} className='linkDestyle'>
                    <div className='p5' style={{textAlign: 'center'}}>15122 Potranco Rd</div>
                    <div className='p5' style={{textAlign: 'center'}}>San Antonio, TX 78245</div>
                    </a>
                </div>
            </Grid>
            <Grid item xs={12} md={3} className={'gridFlex'}>
                <div className='copperColor p10'>Phone</div>
                <div className='p10'><a href="tel:2102549201" className='linkDestyle'>(210) 254-9201</a></div>
            </Grid>
            <Grid item xs={12} md={3} className={'gridFlex'}>
                <div className='copperColor p10'>Social</div>
                <div className='p10'>
                    <a href="https://www.facebook.com/pg/therustynail210/" className='linkDestyle' target="_blank" rel="noreferrer">
                        <FacebookIcon style={{fontSize: '50px'}} />
                    </a>
                </div>
            </Grid>
        </Grid>
        <div className='copyright'>&copy; Copyright 2022. All Rights Reserved.</div>
        </div>
    )
}